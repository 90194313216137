export interface iRBasic {
  createdAt?: Date | string | any;
  createdBy?: string;
  updatedAt?: Date | string;
  updatedBy?: string;
  source?: eRSource;
  keywords?: string[];
}
export enum eRSource {
  'web' = 'web',
  'ios' = 'ios',
  'android' = 'android',
}
export interface iRNameUrl {
  url: string;
  name: string;
}
