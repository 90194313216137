import { Injectable, inject } from '@angular/core';
import * as fireauth from '@angular/fire/auth';
import * as firestore from '@angular/fire/firestore';
import * as firestorage from '@angular/fire/storage';
import { eRFbWhOps } from '../enums/efb-where-op';
import { eRTable } from '../enums/er-tables';
import { iRFbQuerry } from '../models/fb/fb-query.interface';
import { RaFns } from '../utils/ra-fn';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  fauth: fireauth.Auth = inject(fireauth.Auth);
  fstore: firestore.Firestore = inject(firestore.Firestore);
  fstorage: firestorage.FirebaseStorage = inject(firestorage.Storage);

  signupWithEmailAndPassword(email: string, password: string) {
    return fireauth.createUserWithEmailAndPassword(this.fauth, email, password);
  }
  signInWithEmailAndPassword(email: string, password: string) {
    return fireauth.signInWithEmailAndPassword(this.fauth, email, password);
  }
  sendPasswordResetEmail(email: string) {
    return fireauth.sendPasswordResetEmail(this.fauth, email);
  }
  signOut() {
    return fireauth.signOut(this.fauth);
  }
  getDocById(coll: string, documentId: string) {
    const docRef = firestore.doc(this.fstore, coll, documentId);
    return firestore.getDoc(docRef);
  }
  async getDocsByCollection(coll: string) {
    const query = firestore.collection(this.fstore, coll);
    return this.getDocsFromQuery(query);
  }
  async getDocsW1(
    coll: string,
    whereClause: firestore.QueryFieldFilterConstraint,
    orderBy?: firestore.QueryOrderByConstraint
  ) {
    const collection = firestore.collection(this.fstore, coll);
    let query = firestore.query(collection, whereClause);
    if (orderBy) {
      query = firestore.query(collection, whereClause, orderBy);
    }
    return this.getDocsFromQuery(query);
  }
  async setDocById(coll: string, documentId: string, data: any) {
    const doc = firestore.doc(this.fstore, coll, documentId);
    RaFns.setUpdatedAt(data);
    const result = await firestore.setDoc(doc, data, { merge: true });
    return result;
  }
  async addNestedDoc(coll: string, documentId: string, nestedKey: string, data: any) {
    const doc = firestore.collection(this.fstore, coll, documentId, nestedKey);
    RaFns.setCreatedAt(data);
    return firestore.addDoc(doc, data);
  }
  // Update nested document
  async updateNestedDoc(coll: string, documentId: string, nestedKey: string, id: string, data: any) {
    const doc = firestore.doc(this.fstore, coll, documentId, nestedKey, id);
    RaFns.setUpdatedAt(data);
    return firestore.setDoc(doc, data, { merge: true });
  }

  async addDoc(coll: eRTable, data: unknown) {
    RaFns.setCreatedAt(data);
    return firestore.addDoc(this.fbCollection(coll), data);
  }

  async addDocWithId(coll: eRTable, id: string, data: unknown) {
    const collectionRef = firestore.collection(this.fstore, coll);
    const docRef = firestore.doc(collectionRef, id);
    RaFns.setCreatedAt(data);
    return firestore.setDoc(docRef, data);
  }

  formatData(
    querySnapshot: firestore.QuerySnapshot<firestore.DocumentData, firestore.DocumentData>
  ) {
    let result: iRFbQuerry[] = [];
    querySnapshot.forEach((doc) => {
      result.push({ id: doc.id, data: doc.data() as any });
    });
    return result;
  }

  async getDocsFromQuery(query: firestore.Query) {
    const querySnapshot = await firestore.getDocs(query);
    return this.formatData(querySnapshot);
  }
  async getDocsFromQueryUnformat(query: firestore.Query) {
    return firestore.getDocs(query);
  }
  fbWhere(
    key: string,
    val: string | boolean | string[] | null | number,
    op: firestore.WhereFilterOp = eRFbWhOps.equal
  ) {
    return firestore.where(key, op, val)
  }
  fbWhereN(w: firestore.QueryFieldFilterConstraint[]) {
    return firestore.and(...w);
  }
  fbWhereOr(w: (firestore.QueryCompositeFilterConstraint | firestore.QueryFieldFilterConstraint)[]) {
    return firestore.or(...w);
  }
  fbLimit(limt: number) {
    return firestore.limit(limt);
  }
  fbStartAfter(index: number) {
    return firestore.startAfter(index);
  }
  fbStartAt(index: number) {
    return firestore.startAt(index);
  }
  fbEndAt(index: number) {
    return firestore.endAt(index);
  }
  fbGetCount(query: firestore.Query) {
    return firestore.getCountFromServer(query);
  }
  fbStartAfterLast(doc: any) {
    return firestore.startAfter(doc);
  }
  fbCollection(collection: eRTable) {
    return firestore.collection(this.fstore, collection);
  }

  fbOrder(
    orderField: string = 'createdAt',
    sort: firestore.OrderByDirection = 'desc'
  ) {
    return firestore.orderBy(orderField, sort);
  }
  fbQuery2(
    table: eRTable,
    w1: firestore.QueryFieldFilterConstraint,
    w2: firestore.QueryFieldFilterConstraint,
    ob?: firestore.QueryOrderByConstraint
  ) {
    if (ob) {
      return firestore.query(this.fbCollection(table), w1, w2, ob);
    }
    return firestore.query(this.fbCollection(table), w1, w2);
  }
  fbQuery(
    table: eRTable,
    w: firestore.QueryFieldFilterConstraint,
    ob?: firestore.QueryOrderByConstraint
  ) {
    if (ob) {
      return firestore.query(this.fbCollection(table), w, ob);
    }
    return firestore.query(this.fbCollection(table), w);
  }
  fbQueryL(
    table: eRTable,
    w: firestore.QueryCompositeFilterConstraint | null,
    ob: firestore.QueryOrderByConstraint,
    l: firestore.QueryLimitConstraint,
    s?: firestore.QueryStartAtConstraint
  ) {
    const col = this.fbCollection(table);
    if (w) {
      if (s) {
        return firestore.query(col, w, ob, s, l);
      }
      return firestore.query(col, w, ob, l);
    } else {
      if (s) {
        return firestore.query(col, ob, s, l);
      }
      return firestore.query(col, ob, l);
    }
  }
  fbQueryN(
    table: eRTable,
    w?: firestore.QueryCompositeFilterConstraint,
    ob?: firestore.QueryOrderByConstraint,
  ) {
    if (ob && w) {
      return firestore.query(this.fbCollection(table), w, ob);
    } else if (w) {
      return firestore.query(this.fbCollection(table), w);
    } else {
      return firestore.query(this.fbCollection(table));
    }
  }

  async fbQueryNLimit(
    table: eRTable,
    w: firestore.QueryCompositeFilterConstraint,
    ob: firestore.QueryOrderByConstraint,
    limit: firestore.QueryLimitConstraint,
    start?: firestore.QueryStartAtConstraint,
    end?: firestore.QueryEndAtConstraint,
  ) {
    if (start) {
      return firestore.query(this.fbCollection(table), w, ob, start, limit);
    } else if (end) {
      return firestore.query(this.fbCollection(table), w, ob);
    } else {
      return firestore.query(this.fbCollection(table), w, ob);
    }
  }
  async uploadFiles(file: File, path: string) {
    const ref = firestorage.ref(firestorage.getStorage(), path);
    await firestorage.uploadBytes(ref, file);
    return firestorage.getDownloadURL(ref);
  }
  async deleteFile(path: string) {
    const ref = firestorage.ref(firestorage.getStorage(), path);
    return firestorage.deleteObject(ref);
  }

  async fbLimitResponse(
    coll: string,
    whereClause: firestore.QueryFieldFilterConstraint,
    limit: number = 12,
    orderBy?: firestore.QueryOrderByConstraint
  ) {
    const collection = firestore.collection(this.fstore, coll);
    let query = firestore.query(collection, whereClause);
    if (orderBy) {
      query = firestore.query(collection, whereClause, orderBy);
    }
    const querySnapshot = await firestore.getDocs(query);
    return this.formatData(querySnapshot);
  }
  async fbDelete(coll: string, id: string) {
    const docRef = firestore.doc(this.fstore, coll, id);
    return firestore.deleteDoc(docRef);
  }

  fbObserve(
    // query: firestore.DocumentReference<unknown, firestore.DocumentData>, obs: any) {
    query: any,
    // obs: any
    obs: {
      next?: ((snapshot: firestore.DocumentSnapshot<unknown, firestore.DocumentData>) => void) | undefined;
      error?: (error: firestore.FirestoreError) => void;
      complete?: () => void;
    }
  ) {
    return firestore.onSnapshot(query, obs);
  }

  fbGeoPoint(lat: number, lng: number) {
    return new firestore.GeoPoint(lat, lng);
  }

  async fbCount(q: any) {
    try {
      const snapshot = await firestore.getCountFromServer(q);
      return snapshot?.data().count;
    } catch (e) {
      throw e;
    }
  }

  async getBearerToken() {
    return this.fauth?.currentUser?.getIdToken();
  }

  async getDocumentsByIds(col: string, ids: string[]) {
    const docPromises = ids.map(id => {
      const docRef = firestore.doc(this.fstore, col, id);
      return firestore.getDoc(docRef);
    });

    try {
      const docSnapshots = await Promise.all(docPromises);
      const documents = docSnapshots.map(docSnap => {
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          return null;
        }
      }).filter(doc => doc !== null);

      return documents;
    } catch (error) {
      console.error('Error fetching documents:', error);
      return [];
    }
  }

  fbQueryNCases(
    table: eRTable,
    ...params: any
    // ( firestore.QueryCompositeFilterConstraint |  firestore.QueryFieldFilterConstraint | firestore.QueryOrderByConstraint )[]
  ) {
    return firestore.query(this.fbCollection(table), ...params);
  }

  async fbQueryNested(coll: string, documentId: string, nestedKey: string) {
    const doc = firestore.collection(this.fstore, coll, documentId, nestedKey);
    const querySnapshot = await firestore.getDocs(doc);
    return this.formatData(querySnapshot);
  }
  fbQueryNesteds(coll: string, documentId: string, nestedKey: string) {
    const ref = firestore.collection(this.fstore, coll, documentId, nestedKey);
    return firestore.query(ref); 1
  }
  fbQueryNestedRef(coll: string, documentId: string, nestedKey: string) {
    const ref = firestore.collection(this.fstore, coll, documentId, nestedKey);
    const ob = this.fbOrder('createdAt', 'asc');
    return firestore.query(ref, ob);
  }

  async setDocByIdNested(coll: string, documentId: string, nestedKey: string, id: string, data: any) {
    // const ref = firestore.collection(this.fstore, coll, documentId, nestedKey);
    const doc = firestore.doc(this.fstore, coll, documentId, nestedKey, id);
    RaFns.setUpdatedAt(data);
    const result = await firestore.setDoc(doc, data, { merge: true });
    return result;
  }

  messaging() {
    // this.fcm.app.options.
    // firecm.onMessage.
    // firecm.onMessage()
  }

  observeDocumentById(coll: string, id: string, obs: {
    next?: ((snapshot: firestore.DocumentSnapshot<unknown, firestore.DocumentData>) => void) | undefined;
    error?: (error: firestore.FirestoreError) => void;
    complete?: () => void;
  }) {
    const docRef = firestore.doc(this.fstore, coll, id);
    return firestore.onSnapshot(docRef, obs);
  }

  // Get the items from the collection using pagination(not nested), considering start and end
  async getItemsFromCollectionStart(
    table: eRTable,
    w: firestore.QueryCompositeFilterConstraint,
    ob: firestore.QueryOrderByConstraint,
    limit: firestore.QueryLimitConstraint,
    endDoc?: firestore.DocumentSnapshot,
  ) {
    let query;
    if (endDoc) {
      query = firestore.query(firestore.query(this.fbCollection(table), w, ob, limit), firestore.startAfter(endDoc));
    } else {
      query = firestore.query(this.fbCollection(table), w, ob, limit);
    }
    const querySnapshot = await firestore.getDocs(query);
    return this.formatData(querySnapshot);
  }
}
