import { FormGroup } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { iRMarkersO } from '../../pages/map/ra-map.class';
import { eRVehicleEvents } from '../enums/er-route-events';
import { eRRoles } from '../enums/eRRoles';
import { eRSource } from '../models/fb/basic.interface';
import { iRFVehicle } from '../models/fb/fb-query.interface';
import { RaCONSTS } from './ra-consts';

interface fValues {
  [key: string]: any;
}
export const RaFns = {
  getkeywords(val: string) {
    const res = [];
    const s = Array.from(val.toLowerCase());
    const len = s.length;
    for (let i = 0; i < len; i++) {
      for (let j = i + 1; j <= len; j++) {
        res.push(s.slice(i, j).join('').trim());
      }
    }
    return res;
  },
  createKeywords(request: any, values: string[]) {
    const words = values.filter((e) => e).map((e) => e.trim());
    let res: string[] = [];
    words.forEach((e) => {
      const s = this.getkeywords(e);
      res = [...res, ...s];
    });
    request.keywords = this.getUniqueList(res);
  },
  getUniqueList(values: any[]) {
    return [...new Set(values)];
  },
  getControlValue(userForm: FormGroup, name: string) {
    return userForm?.controls[name]?.value;
  },
  setControlValue(userForm: FormGroup, name: string, value: any) {
    userForm?.controls[name]?.setValue(value);
  },
  setControlsValue(userForm: FormGroup, fv: fValues) {
    Object.keys(fv).forEach((e) => {
      userForm?.controls[e]?.setValue(fv[e]);
    });
  },
  setCreatedAt(data: any) {
    data.createdAt = new Date();
    data.source = eRSource.web;
  },
  setUpdatedAt(data: any) {
    data.updatedAt = new Date();
  },
  getPosition(success: PositionCallback, error: PositionErrorCallback) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      error;
    }
  },
  epochMs() {
    return Math.floor(Date.now());
  },
  epochTime(date: Date) {
    return Math.floor(date.getTime() / 1000);
  },
  epochDate(date: Date) {
    const y = date.getFullYear();
    const m = date.getMonth();
    const d = date.getDate();
    return Math.floor(new Date(y, m, d).getTime() / 1000);
  },
  clone(val: Object) {
    return JSON.parse(JSON.stringify(val));
  },
  genKeyValue(val: Object) {
    return Object.entries(val).map(([key, value]) => ({ key, value }));
  },
  sortArrayByAnother(o: any[], s: any[]) {
    o.sort((a, b) => {
      return s.indexOf(a) - s.indexOf(b);
    });
  },
  findElement(id: string) {
    return document.getElementById(id) as HTMLDivElement;
  },
  createElement(
    tag: string,
    c: string[],
    v?: string | HTMLElement,
    id?: string,
  ) {
    const e = document.createElement(tag);
    if (id) e.id = id;
    c?.forEach((cn) => e.classList.add(cn));
    switch (typeof v) {
      case 'string':
        e.innerHTML = v;
        break;
      case 'object':
        e.appendChild(v);
        break;
      default:
        break;
    }
    return e;
  },
  createHtmlTag(v: {
    tag?: string;
    classList?: string[];
    html?: string | HTMLElement;
    id?: string;
    append?: HTMLElement[];
    fncb?: { name?: string; cb: () => {} };
    attr?: { name: string; value: string };
  }) {
    const e = document.createElement(v?.tag ?? 'div');
    if (v?.id) e.id = v?.id;
    if (v?.classList && v?.classList?.length > 0) {
      v?.classList?.forEach((cn) => e.classList.add(cn));
    } else {
      e.classList.add('text-center');
    }
    if (v?.html) {
      switch (typeof v?.html) {
        case 'string':
          e.innerHTML = v?.html;
          break;
        case 'object':
          e.appendChild(v?.html);
          break;
        default:
          break;
      }
    }
    if (v?.append && v?.append?.length > 0) {
      v.append.forEach((element) => {
        e.appendChild(element);
      });
    }
    if (v?.fncb) {
      const name = v?.fncb?.name ?? 'click';
      e?.addEventListener(name, v?.fncb.cb);
    }
    if (v?.attr) {
      e?.setAttribute(v?.attr?.name, v?.attr?.value);
    }
    return e;
  },
  padTwoDigits(num: number) {
    return num.toString().padStart(2, '0');
  },
  dateInYyyyMmDdHhMmSs(date: Date, dateDiveder: string = '-') {
    return (
      [
        this.padTwoDigits(date.getMonth() + 1),
        this.padTwoDigits(date.getDate()),
        date.getFullYear(),
      ].join(dateDiveder) +
      ' - ' +
      [
        this.padTwoDigits(date.getHours()),
        this.padTwoDigits(date.getMinutes()),
        this.padTwoDigits(date.getSeconds()),
      ].join(':')
    );
  },
  convertToTimeZone(value: number) {
    return new Date(value * 1000).toLocaleString('en-US', {
      timeZone: 'America/New_York',
    });
  },
  convertEpochToAMPM(epoch: number): string {
    const date = new Date(epoch * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    return formattedDate;
  },
  convertMeterToFeet(m: number) {
    return 3.28084 * m;
  },
  convertSecToHours(n: number) {
    return n / 3600;
  },
  padwithZero(val: number, pn: number = 2, pc: string = '0') {
    return String(val).padStart(pn, pc);
  },
  convertSecondsToHHMM(
    seconds: number,
    isSec: boolean = true,
    isSep: boolean = false,
  ): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const rsec = Math.floor((seconds % 3600) % 60);
    if (isSep) {
      let res = `${this.padwithZero(hours)}:${this.padwithZero(minutes)}`;
      if (isSec) {
        res = `${res}:${this.padwithZero(rsec)}`;
      }
      return res;
    } else {
      let res = `${this.padwithZero(hours)} h ${this.padwithZero(minutes)} m`;
      if (rsec > 0 && isSec) {
        res = `${res} ${this.padwithZero(rsec)} s`;
      }
      return res;
    }
  },
  convertSecondsToMMSS(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const rsec = Math.floor((seconds % 3600) % 60);
    let res = '';
    res = `${this.padwithZero(minutes)}:`;
    if (rsec > 0) {
      res = `${res} ${this.padwithZero(rsec)} min`;
    }
    return res;
  },
  convertFeetToMeter(f: number) {
    return 0.3048 * f;
  },
  makeId(f: string) {
    return `#${f}`;
  },
  nextDate(date: Date, isNext = true) {
    const d = isNext
      ? moment(date).add(1, 'days')
      : moment(date).subtract(1, 'days');
    return d.toDate();
  },
  isToDaysDate(date: Date) {
    const today = new Date();
    return this.dateFormatYYYY(date) === this.dateFormatYYYY(today);
  },
  isSameDate(date1: Date, date2: Date) {
    return this.dateFormatYYYY(date1) === this.dateFormatYYYY(date2);
  },
  dateFormatYYYY(date: Date) {
    return moment(date).format(RaCONSTS.dateFormats.ymd);
  },
  dateFormatMMDDYYYY(date: Date | string) {
    return moment(date).format(RaCONSTS.dateFormats.mdy);
  },
  dateFormatMMDD(date: Date | string) {
    return moment(date).format(RaCONSTS.dateFormats.md);
  },
  findKeyByValue(obj: any, value: any): string | undefined {
    for (const [key, val] of Object.entries(obj)) {
      if (val === value) {
        return key;
      }
    }
    return undefined;
  },
  fixdedDecimalPoints(v: number, n: number = 2) {
    return parseFloat(v.toFixed(n));
  },
  convertHoursToSeconds(val: number) {
    return val * 60 * 60;
  },
  convertMilesToKms(val: number) {
    return val * 1.60934;
  },
  convertKmsToMiles(km: number) {
    return km * 0.62137;
  },
  showKmsInMph(val: number) {
    return this.fixdedDecimalPoints(this.convertKmsToMiles(val));
  },
  roundUpToNext5(num: number): number {
    return Math.ceil(num / 5) * 5;
  },
  unsubscribeList(val: Subscription[]) {
    val.forEach((element) => {
      if (element && !element?.closed) element?.unsubscribe();
    });
  },
  userIdArray(val: string[]) {
    return val.sort().join(',');
  },
  setTimeOnDates(startDate: Date, endDate: Date) {
    const s = moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
    const e = moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
    return { s, e };
  },
  getDefaultDates(days: number = 7) {
    const endDate = new Date();
    const startDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() - days,
    );
    const { s, e } = this.setTimeOnDates(startDate, endDate);
    return { startDate: s, endDate: e };
  },
  activityTypeData(actType: string, _iRMarkersO?: iRMarkersO) {
    const val: iRActTypeREturn = {
      color: '',
      icon: '',
      asset: '',
    };
    switch (actType) {
      case 'end':
        val.icon = RaCONSTS.icons.start;
        val.color = 'ra-bg-purple';
        val.asset = 'assets/svg/start.svg';
        if (_iRMarkersO) val.el = _iRMarkersO.end;
        break;
      case 'idle':
        val.icon = RaCONSTS.icons.pause;
        val.color = 'ra-bg-grey';
        val.asset = 'assets/svg/pause.svg';
        if (_iRMarkersO) val.el = _iRMarkersO.idle;
        break;
      case 'moving':
        val.icon = RaCONSTS.icons.play;
        val.color = 'ra-bg-green';
        val.asset = 'assets/svg/play.svg';
        if (_iRMarkersO) val.el = _iRMarkersO.moving;
        break;
      case 'start':
        val.icon = RaCONSTS.icons.start;
        val.color = 'ra-bg-orange';
        val.asset = 'assets/svg/start.svg';
        if (_iRMarkersO) val.el = _iRMarkersO.start;
        break;
      case 'stopped':
        val.icon = RaCONSTS.icons.stop;
        val.color = 'ra-bg-red';
        val.asset = 'assets/svg/stop.svg';
        if (_iRMarkersO) val.el = _iRMarkersO.stop;
        break;
      default:
        break;
    }
    return val;
  },
  routeEvents(eventType: string, _iRMarkersO?: iRMarkersO) {
    const val: iREventTypeREturn = {
      color: '',
      title: '',
      eventType,
    };
    switch (eventType) {
      case eRVehicleEvents.harshAcceleration:
        val.title = 'Acceleration';
        val.color = RaCONSTS.colorCodes.accel;
        if (_iRMarkersO) val.el = _iRMarkersO.acceleration;
        break;
      case eRVehicleEvents.harshBraking:
        val.title = 'Braking';
        val.color = RaCONSTS.colorCodes.brake;
        if (_iRMarkersO) val.el = _iRMarkersO.braking;
        break;
      case eRVehicleEvents.harshCornering:
        val.title = 'Cornering';
        val.color = RaCONSTS.colorCodes.corner;
        if (_iRMarkersO) val.el = _iRMarkersO.cornering;
        break;
      case eRVehicleEvents.overSpeed:
        val.title = 'Over speed';
        val.color = RaCONSTS.colorCodes.overspeed;
        if (_iRMarkersO) val.el = _iRMarkersO.overspeed;
        break;
      default:
        break;
    }
    return val;
  },
  vehicleSpeedInMph(value: iRFVehicle) {
    const d = value?.data.flespiLastMessage;
    if (d) {
      const f = JSON.parse(d);
      const s = f['can.vehicle.speed'];
      if (s && value?.data.isOn) {
        return Math.round(this.convertKmsToMiles(s)).toString();
      }
      return 0;
    }
    return null;
  },
  flesiTimestamp(value: string, timestampField: string = 'timestamp') {
    const jsonObj = JSON.parse(value);
    const timestamp = jsonObj[timestampField];
    if (!timestamp) return null;
    const jsDate = new Date(timestamp * 1000);
    return jsDate;
  },
  isAccessToApp(role: string | undefined) {
    const isAccess =
      role === eRRoles.manager ||
      role === eRRoles.owner ||
      role === eRRoles.admin ||
      role === eRRoles.mechanic ||
      role === eRRoles.user;
    return role && isAccess;
  },
  isVehicleSentDataToServerToday(v: iRFVehicle) {
    let m = v?.data?.flespiLastMessage;
    if (!m) return false;
    const dd = RaFns.flesiTimestamp(m);
    if (dd && RaFns.isToDaysDate(dd)) {
      return true;
    }
    return false;
  },
};
export interface iREventTypeREturn {
  color: string;
  title: string;
  eventType?: string;
  el?: google.maps.marker.AdvancedMarkerElement[];
}
export interface iRActTypeREturn {
  color: string;
  asset: string;
  icon: string;
  el?: google.maps.marker.AdvancedMarkerElement[];
}
